import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useLoadingStore = defineStore('loading', () => {
  const isLoadingReport = ref(false)

  function setLoading(state: boolean) {
    isLoadingReport.value = state
  }

  return { isLoadingReport, setLoading }
})
